<template>
  <div class="coins-box">
    <div class="coin-con">
      <div class="tip-paypal">
        <!-- <img loading="lazy" src="@/assets/images/img-paypal.png" alt="" /> -->
        <payCom ref="payCom" v-show="showCoinList"></payCom>
      </div>
      <div class="the-list" v-show="showCoinList">
        <div
          class="one-coin fx-vb"
          v-show="showCoinList"
          v-for="(item, index) in priList[0].items"
          :key="item.id"
          :class="{cur: index === 0}"
          :data-id="item.id"
          @click="payClick(item)"
          :id='"item-"+item.id'
          data-plan-type="MERCHANT_INITIATED_BILLING"
        >
          <div class="most-tip fx-vc" v-if="index === 0">Most Poupular</div>
          <div class="info fx-vc">
            <span v-if="index==0">Weekly</span>
            <span v-else>Yearly</span>
            <span
              >$<strong>{{ item.price/100 }}</strong></span
            >
            <del>${{ item.origin_price/100 }}</del>
          </div>
          <div class="btn fx-vc">Subscribe</div>
          <div class="icon-hand">
            <img loading="lazy" src="@/assets/images/icon-hand.png" alt="" />
          </div>
        </div>
        <!-- todo: comment ready  to apply -->
        <!-- <div class="one-coin fx-vb cur">
          <div class="info fx-vc">
            <span>Weekly</span>
            <span>$<strong>1.99</strong></span>
            <del>$19.00</del>
          </div>
          <div class="btn fx-vc">Subscribe</div>
          <div class="icon-hand">
            <img loading="lazy" src="@/assets/images/icon-hand.png" alt="" />
          </div>
        </div>
        <div class="one-coin fx-vb">
          <div class="info fx-vc">
            <span>Weekly</span>
            <span>$<strong>1.99</strong></span>
            <del>$19.00</del>
          </div>
          <div class="btn fx-vc">Subscribe</div>
          <div class="icon-hand">
            <img loading="lazy" src="@/assets/images/icon-hand.png" alt="" />
          </div>
        </div> -->
      </div>
    </div>

    <div class="coin-tip">
      <img loading="lazy" src="@/assets/images/coin-tip.png" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import payCom from "../pay/payCom.vue";
import { rechargeListApi, orderSubmitApi,GetReportParam,getAppID } from "@/api";
import Cookies from "js-cookie";
import { genRandomString  } from "@/util/index";

export default {
  name: "CoinsBox",
  data() {
    return {
      // showCoinList: true,
      priList: [
        {
          items: [],
        },
      ],
    };
  },
  components: { payCom },
  computed: {
    ...mapState({
      showCoinList: (state) =>  {
      const userData = state.userData;
      if(userData && userData.is_vip){
        return false;
      }
      return true;
    }, 
    }),
    // userData: (state) => state.userData,
 
  },
  methods: {
    async rechargeList() {
      // const $this = this;
      if(!this.showCoinList){
        return;
      }
      const abuid = Cookies.get("abuid");
      const user = abuid //this.$store.state.userData.name || abuid;
      const userToken = Cookies.get('atoken') || "";
      const reportData = GetReportParam();
      const param = {
        app_id: getAppID() || "novellettes",
        user: user,
        tid: userToken ,//reportData.tid,
        sid: userToken,
        cid: ""+reportData.cid,
        lan: window.navigator.language,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
      const res = await rechargeListApi(param);
      // .then((res) => {
      //   this.priList = res.data.vip_list;
      //   return true;
      // })
      this.priList = res.data.vip_list; 
      // .then(() => { }); });
        
     
    },
    payClick(item) {
      console.log("window paypal:",window.paypal); 
      console.log(1, item);
      // const { items } = item;
      const { token } = item; // price, 
      const abuid = Cookies.get("abuid");
      const user = abuid //this.$store.state.userData.name || abuid;
      const userToken = Cookies.get('atoken') || "";
      const reportData = GetReportParam();
      const toast = this.$toast.loading({
        message: "You will be redirected to PayPal Shortly.",
        duration: 0,
        overlay:true,
      });
      orderSubmitApi({
        app_id: getAppID() || "novellettes",
        user: user,
        tid: userToken ,//reportData.tid,
        sid: userToken,
        cid: ""+reportData.cid,
        lan: window.navigator.language,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        pay_type: 1,
        cmid: genRandomString(24),
        token: token,
        return_url: location.href,
        source: "1",
      }).then(res=>{
        console.log("orderSubmitApi:",res);
        if(res.code === 0){
          // window.open(res.data.command,"_blank");
          location.href = res.data.command;
          // window.location.replace(res.data.command);
        }
        setInterval(() => {
          toast.clear()  
        }, 1500);
        
      }).catch(e=>{
        setInterval(() => {
          toast.clear()  
        }, 1500);
        console.log("orderSubmitApi error:",e);
      });
    },
  },
  mounted() {
    this.rechargeList();
  },
};
</script>

<style lang="less">
@import url("./index.less");
</style>
